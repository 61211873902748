import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Trans, useTranslation } from "react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/layout/Layout";
import Seo from "../components/seo";
import SectionLanding from "../components/section/SectionLanding";
import { Link } from "gatsby-plugin-react-i18next";

import Subrayado2 from "../svg/subrayados/subrayado02.svg";

export default function NotFoundPage() {
	const { t } = useTranslation();

	return (
		<Layout>
			<Seo title="404 Aquí no hay nada" />
			<div className="blackOverlay" style={{ opacity: 0.25 }} />
			<StaticImage
				src={`../images/404.jpg`}
				className="landing-bg"
				alt="Section background"
				layout="fullWidth"
			/>
			<div className="landingSection landing404">
				<SectionLanding
					title={t("Oh, parece que nos hemos perdido")}
					subtitle={t("La página que buscas no está disponible")}
					onlyLanding={true}
				>
					<Link to="/" className="link404">
						<h3>
							<Trans>Volver al origen</Trans>
							<Subrayado2 />
						</h3>
					</Link>
				</SectionLanding>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`;
